(function() {
    'use strict';

    angular.module('ionicFlag', [
        'ionicAlert',
        'ionicData',
        'ionicDataTable',
        'ionicLogin',
        'ionicRequest',
        'ionicTag',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicFlag').service('FlagCreateService', FlagCreateService);

    FlagCreateService.$inject = [
        'FlagListService',
        'RequestManagementService'
    ];

    function FlagCreateService(
        FlagListService,
        RequestManagementService
    ) {
        var FlagCreateService = this;

        FlagCreateService.createFlag = createFlag;
        function createFlag(newFlag) {
            var options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'tag_groups');
            options = RequestManagementService.setAction(options, 'add');

            options = RequestManagementService.setData(options, newFlag);

            return ListManager.create(options);
        }

        FlagCreateService.getListManager = getListManager;
        function getListManager(userId) {
            return FlagListService.getListManager(userId);
        }

        FlagCreateService.reset = reset;
        function reset() {

        }

        var ListManager = FlagListService.getListManager();

        FlagCreateService.reset();

        return FlagCreateService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicFlag').service('FlagEditService', FlagEditService);

    FlagEditService.$inject = [
        'FlagListService',
        'RequestManagementService',
        'TagListService'
    ];

    function FlagEditService(
        FlagListService,
        RequestManagementService,
        TagListService
    ) {
        var FlagEditService = this;

        FlagEditService.deleteFlag = deleteFlag;
        function deleteFlag(flag) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'tag_groups');
            options = RequestManagementService.setAction(options, 'delete', flag.id);

            return ListManager.remove(options);
        }

        FlagEditService.editFlag = editFlag;
        function editFlag(flag) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'tag_groups');
            options = RequestManagementService.setAction(options, 'edit', flag.id);

            options = RequestManagementService.setData(options, flag);

            return ListManager.edit(options);
        }

        FlagEditService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        FlagEditService.getFlag = getFlag;
        function getFlag() {
            return ListManager.getDefault();
        }

        FlagEditService.getTags = getTags;
        function getTags() {
            return TagListService.getTags();
        }

        FlagEditService.reset = reset;
        function reset() {

        }

        var ListManager = FlagListService.getListManager();

        FlagEditService.reset();

        return FlagEditService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicFlag').service('FlagListService', FlagListService);

    FlagListService.$inject = [
        'DefaultVariableService',
        'ListManagementService',
        'RequestManagementService'
    ];

    function FlagListService(
        DefaultVariableService,
        ListManagementService,
        RequestManagementService
    ) {
        var FlagListService = this;

        FlagListService.buildOptions = buildOptions;
        function buildOptions(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'tag_groups');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setParams(
                options,
                [
                    'Tags'
                ]
            );

            return options;
        }

        FlagListService.getList = getList;
        function getList() {
            return ListManager.getList();
        }

        FlagListService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        FlagListService.getMore = getMore;
        function getMore(options) {
            options = FlagListService.buildOptions(options);

            return ListManager.getMore(options);
        }

        FlagListService.getPage = getPage;
        function getPage(options) {
            options = FlagListService.buildOptions(options);

            return ListManager.getPage(options);
        }

        FlagListService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager(
            FlagListService.getMore
        );

        FlagListService.reset();

        return FlagListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicFlag').service(
        'FlagManagementService',
        FlagManagementService
    );

    FlagManagementService.$inject = [
        'FlagCreateService',
        'FlagEditService',
        'FlagListService'
    ];

    function FlagManagementService(
        FlagCreateService,
        FlagEditService,
        FlagListService

    ) {
        var FlagManagementService = this;

        FlagManagementService.getFlags = getFlags;
        function getFlags(options) {
            return FlagListService.getPage(options);
        }

        FlagManagementService.createFlag = createFlag;
        function createFlag(newFlag) {
            return FlagCreateService.createFlag(newFlag);
        }

        FlagManagementService.deleteFlag = deleteFlag;
        function deleteFlag(flag) {
            return FlagEditService.deleteFlag(flag);
        }

        FlagManagementService.editFlag = editFlag;
        function editFlag(flag) {
            return FlagEditService.editFlag(flag);
        }

        FlagManagementService.reset = reset;
        function reset() {

        }

        FlagManagementService.reset();

        return FlagManagementService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicFlag').controller(
        'FlagEditController',
        FlagEditController
    );

    FlagEditController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'FlagEditService',
        'LoginService',
        '$scope'
    ];

    function FlagEditController(
        AlertService,
        DefaultVariableService,
        FlagEditService,
        LoginService,
        $scope
    ) {
        var FlagEditController = this;

        $scope.$watch(
            'flag',
            function(flag) {
                FlagEditController.flag = DefaultVariableService.getObject(flag);
            }.bind(FlagEditController)
        );

        $scope.$watch(
            'onDelete',
            function(onDelete) {
                FlagEditController.onDelete = DefaultVariableService.getPromise(onDelete);
            }
        );

        $scope.$watch(
            'onEdit',
            function(onEdit) {
                FlagEditController.onEdit = DefaultVariableService.getPromise(onEdit);
            }
        );

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                FlagEditController.onCancel = DefaultVariableService.get(
                    onCancel,
                    false
                );
            }
        );

        FlagEditController.cancel = cancel;
        function cancel() {
            FlagEditController.onCancel();
        }

        FlagEditController.editFlag = editFlag;
        function editFlag(flag) {
            FlagEditController.isEditingFlag = true;

            flag = DefaultVariableService.get(
                flag,
                FlagEditController.flag
            );

            FlagEditService.editFlag(flag).then(
                function(flag) {
                    if (flag) {
                        AlertService.addMessage('FLAG EDITED.');

                        FlagEditController.onEdit();
                    }

                    return flag;
                }
            ).finally(
                function () {
                    FlagEditController.isEditingFlag = false;
                }
            );
        }

        FlagEditController.deleteFlag = deleteFlag;
        function deleteFlag(flag) {
            FlagEditController.isDeletingFlag =  true;

            flag = DefaultVariableService.get(
                flag,
                FlagEditController.flag
            );

            return FlagEditService.deleteFlag(flag).then(
                function(response) {
                    if (response) {
                        AlertService.addMessage('FLAG DELETED.');

                        FlagEditController.onEdit();
                    }
                }
            ).finally(
                function() {
                    FlagEditController.isDeletingFlag =  false;
                }
            );
        }

        FlagEditController.setTags = setTags;
        function setTags(tags) {
            FlagEditController.flag.tags = tags;
        }

        FlagEditController.reset = reset;
        function reset() {
            FlagEditController.flag = DefaultVariableService.getObject();
            FlagEditController.flag.tags = [];
        }

        FlagEditController.init = init;
        function init() {
            FlagEditController.reset();
        }

        LoginService.register(FlagEditController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicFlag').directive(
        'ionicFlagEdit',
        ionicFlagEdit
    );

    function ionicFlagEdit() {
        return {
            controller:   'FlagEditController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                flag:     '=',
                onDelete: '=',
                onEdit:   '=',
                onCancel: '='
            },
            template:'<div><label class="item item-input item-floating-label"><span class="input-label">NAME</span> <input type="text" placeholder="NAME" data-ng-model="ctrl.flag.name"></label><div><h1>TAGS</h1><ionic-tag-select on-update="ctrl.setTags" target="ctrl.flag.tags"></ionic-tag-select></div><div class="row"><button class="button button-full accept-button" data-ng-class="{ \'col-xl-4\': ctrl.onCancel, \'col-xl-6\': !ctrl.onCancel }" data-ng-click="ctrl.editFlag()" data-ng-disabled="ctrl.isEditingFlag">{{ ctrl.isEditingFlag ? \'EDITING...\' : \'EDIT\' }}</button> <button class="button button-full decline-button" data-ng-class="{ \'col-xl-4\': ctrl.onCancel, \'col-xl-6\': !ctrl.onCancel }" data-ng-click="ctrl.deleteFlag()" data-ng-disabled="ctrl.isCreatingFlag || ctrl.isDeletingFlag">{{ ctrl.isDeletingFlag ? \'DELETING...\' : \'DELETE\' }}</button> <button data-ng-if="ctrl.onCancel" class="button button-full" data-ng-class="{ \'col-xl-4\': ctrl.onCancel }" data-ng-click="ctrl.cancel()" data-ng-disabled="ctrl.isEditingFlag">CANCEL</button></div></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicFlag').controller(
        'FlagOptionsController',
        FlagOptionsController
    );

    FlagOptionsController.$inject = [
        'DefaultVariableService',
        'LoginService',
        'FlagListService',
        '$scope'
    ];

    function FlagOptionsController(
        DefaultVariableService,
        LoginService,
        FlagListService,
        $scope
    ) {
        var FlagOptionsController = this;

        $scope.$watch(
            'onSelectTag',
            function(onSelectTag) {
                FlagOptionsController.onSelectTag = DefaultVariableService.get(
                    onSelectTag,
                    false
                );
            }.bind(FlagOptionsController)
        );

        $scope.$watch(
            'target',
            function(target) {
                FlagOptionsController.setTarget(target);
            }.bind(FlagOptionsController)
        );

        FlagOptionsController.loadFlags = loadFlags;
        function loadFlags() {
            FlagOptionsController.isLoadingFlags = true;

            FlagListService.getMore().then(
                function(data) {
                    if (data) {
                        FlagOptionsController.flags = DefaultVariableService.getArray(
                            data.tag_groups
                        );

                        FlagOptionsController.setTarget();
                    }

                    return data;
                }
            ).finally(
                function() {
                    FlagOptionsController.isLoadingFlags = false;
                }
            );
        }

        FlagOptionsController.selectTag = selectTag;
        function selectTag(tag) {
            if (FlagOptionsController.onSelectTag) {
                FlagOptionsController.onSelectTag(tag);
            }
        }

        FlagOptionsController.selectTags = selectTags;
        function selectTags() {
            if (FlagOptionsController.onSelectTags) {
                var tags = [];

                var keys = Object.keys(
                    FlagOptionsController.selectedTags
                );

                var keysLength = keys.length;

                for (var i = 0; i < keysLength; i++) {
                    tags.push(
                        FlagOptionsController.selectedTags[keys[i]]
                    );
                }

                FlagOptionsController.onSelectTags(tags);
            }
        }

        FlagOptionsController.setTarget = setTarget;
        function setTarget(target) {
            FlagOptionsController.target = DefaultVariableService.getArray(
                target,
                FlagOptionsController.target
            );

            var targetLength = FlagOptionsController.target.length;
            var flagsLength = FlagOptionsController.flags.length;
            if (targetLength !== 0 && flagsLength !== 0) {
                FlagOptionsController.selectedTags = {};

                for (var i = 0; i < targetLength; i++) {
                    var targetObject = DefaultVariableService.getObject(
                        FlagOptionsController.target[i]
                    );

                    if (targetObject.id !== 0) {
                        for (var j = 0; j < flagsLength; j++) {
                            var flag = DefaultVariableService.getObject(
                                FlagOptionsController.flags[j]
                            );

                            var tags = DefaultVariableService.getArray(
                                flag.tags
                            );

                            var tagsLength = tags.length;
                            for (var k = 0; k < tagsLength; k++) {
                                var tag = DefaultVariableService.getObject(
                                    flag.tags[k]
                                );

                                if (targetObject.id === tag.id) {
                                    FlagOptionsController.selectedTags[flag.name] = targetObject;
                                }
                            }
                        }
                    }
                }
            }
        }

        FlagOptionsController.reset = reset;
        function reset() {
            FlagOptionsController.isLoadingFlags = true;

            FlagOptionsController.flags = [];
        }

        FlagOptionsController.init = init;
        function init() {
            FlagOptionsController.reset();
            FlagOptionsController.loadFlags();
        }

        LoginService.register(FlagOptionsController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicFlag').directive(
        'ionicFlagOptions',
        ionicFlagOptions
    );

    function ionicFlagOptions() {
        return {
            controller:   'FlagOptionsController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onSelectTag:  '=',
                onSelectTags: '=',
                target:       '='
            },
            template:'<div><div data-ng-if="!ctrl.isLoadingFlags & ctrl.flags.length === 0" class="item" data-ng-repeat="flag in ctrl.flags">{{ flag.name }}<ion-list data-ng-if="flag.tags.length !== 0"><ion-radio data-ng-model="ctrl.selectedTags[flag.name].id" data-ng-repeat="tag in flag.tags" data-ng-click="ctrl.selectTag(tag)" data-ng-value="tag.id">{{ tag.name }}</ion-radio></ion-list></div><div data-ng-if="!ctrl.isLoadingFlags && ctrl.flags.length === 0">NO FLAGS</div><span data-ng-if="ctrl.isLoadingFlags"><ion-spinner class="centerSpinner"></ion-spinner></span></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicFlag').controller('FlagListController', FlagListController);
    
    FlagListController.$inject = [
        'DefaultVariableService',
        'FlagListService',
        'LoginService'
    ];
    
    function FlagListController(
        DefaultVariableService,
        FlagListService,
        LoginService
    ) {
        var FlagListController = this;
        
        FlagListController.clear = clear;
        function clear() {
            FlagListController.reset();
            FlagListService.reset();
        }

        FlagListController.loadFlags = loadFlags;
        function loadFlags() {
            FlagListController.isLoadingFlags = true;

            return FlagListService.getMore().then(
                function(data) {
                    if (data) {
                        FlagListController.flags = DefaultVariableService.getArray(
                            data.tag_groups
                        );
                    }

                    FlagListController.canLoadMore = DefaultVariableService.get(
                        data.canLoadMore,
                        false
                    );

                    return data;
                }
            ).finally(
                function() {
                    FlagListController.isLoadingFlags = false;
                }
            );
        }

        FlagListController.reset = reset;
        function reset() {
            FlagListController.isLoadingFlags = false;

            FlagListController.flags = [];
        }

        FlagListController.init = init;
        function init() {
            FlagListController.reset();
            FlagListController.loadFlags();
        }

        LoginService.register(FlagListController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicFlag').directive('ionicFlagList', ionicFlagList);

    function ionicFlagList() {
        return {
            controller:   'FlagListController',
            controllerAs: 'flagListCtrl',
            restrict:     'E',
            template:'<div data-ng-if="flagListCtrl.flags.length !== 0"><ul class="margin-leftr-15"><li data-ng-repeat="flag in flagListCtrl.flags">Flag Group ID: {{ flag.id }}<div class="margin" data-ng-repeat="tag in flag.tags">Tag ID: {{ tag.id }}<br>Name: {{ tag.name }}</div></li></ul></div><div data-ng-if="!flagListCtrl.isLoadingFlags && flagListCtrl.flags.length === 0">No Flags</div><div data-ng-if="flagListCtrl.isLoadingFlags">Loading...</div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicFlag').controller(
        'FlagManagementController',
        FlagManagementController
    );
    
    FlagManagementController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'LoginService',
        'FlagManagementService'
    ];
    
    function FlagManagementController(
        AlertService,
        DefaultVariableService,
        LoginService,
        FlagManagementService
    ) {
        var FlagManagementController = this;

        FlagManagementController.clear = clear;
        function clear() {
            FlagManagementController.reset();
            FlagManagementService.reset();
        }

        FlagManagementController.createFlag = createFlag;
        function createFlag() {
            FlagManagementController.isCreatingFlag = true;

            return FlagManagementService.createFlag(FlagManagementController.newFlag).then(
                function(response) {
                    if (response) {
                        FlagManagementController.loadFlags();

                        AlertService.addMessage('FLAG CREATED.');

                        FlagManagementController.resetNewFlag();

                        FlagManagementController.showCreator = false;
                    }
                }
            ).finally(
                function() {
                    FlagManagementController.isCreatingFlag = false;
                }
            );
        }

        FlagManagementController.onDelete = onDelete;
        function onDelete() {
            FlagManagementController.loadFlags();

            FlagManagementController.resetFlag();
        }

        FlagManagementController.onEdit = onEdit;
        function onEdit() {
            FlagManagementController.loadFlags();

            FlagManagementController.resetFlag();
        }

        FlagManagementController.loadFlags = loadFlags;
        function loadFlags(options) {
            FlagManagementController.isLoadingFlags = true;

            return FlagManagementService.getFlags(options).then(
                function(data) {
                    if (data) {
                        FlagManagementController.flags = DefaultVariableService.getArray(
                            data.tag_groups
                        );

                        FlagManagementController.count = DefaultVariableService.getInteger(
                            data.count,
                            0
                        );
                    }

                    return data;
                }

            ).finally(
                function () {
                    FlagManagementController.isLoadingFlags = false;
                }
            );
        }

        FlagManagementController.selectFlag = selectFlag;
        function selectFlag(flag) {
            FlagManagementController.flag = DefaultVariableService.getObject(
                flag
            );
        }

        FlagManagementController.toggleCreator = toggleCreator;
        function toggleCreator() {
            FlagManagementController.showCreator = !FlagManagementController.showCreator;
        }

        FlagManagementController.reset = reset;
        function reset() {
            FlagManagementController.columns = [
                {
                    position: 1,
                    key:      'name',
                    title:    'NAME'
                }
            ];

            FlagManagementController.resetNewFlag();

            FlagManagementController.resetFlag();
        }

        FlagManagementController.resetNewFlag = resetNewFlag;
        function resetNewFlag() {
            FlagManagementController.newFlag = {};
        }

        FlagManagementController.resetFlag = resetFlag;
        function resetFlag() {
            FlagManagementController.flag = DefaultVariableService.getObject();
        }

        FlagManagementController.init = init;
        function init() {
            FlagManagementController.reset();
            FlagManagementController.loadFlags();
        }

        LoginService.register(FlagManagementController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicFlag').directive(
        'ionicFlagManagement',
        ionicFlagManagement
    );

    function ionicFlagManagement() {
        return {
            controller:   'FlagManagementController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<div data-ng-if="!ctrl.isLoadingFlags"><div data-ng-if="ctrl.showCreator"><h1>NEW FLAG GROUP</h1><label class="item item-input item-floating-label"><span class="input-label">NAME</span> <input type="text" placeholder="NAME" data-ng-model="ctrl.newFlag.name"></label><div class="row"><button class="button button-full accept-button col-xl-6" data-ng-click="ctrl.createFlag()" data-ng-disabled="ctrl.isCreatingFlag">{{ ctrl.isCreatingFlag ? \'CREATING...\' : \'CREATE\' }}</button> <button class="button button-full decline-button col-xl-6" data-ng-click="ctrl.toggleCreator()" data-ng-disabled="ctrl.isCreatingFlag">CANCEL</button></div></div><div data-ng-if="!ctrl.showCreator"><button class="button button-full accept-button" data-ng-click="ctrl.toggleCreator()">CREATING NEW FLAG</button><ionic-data-table data-ng-if="ctrl.flag.id === 0" columns="ctrl.columns" objects="ctrl.flags" on-request="ctrl.loadFlags" on-select="ctrl.selectFlag" total="ctrl.count"></ionic-data-table><ionic-flag-edit data-ng-if="ctrl.flag.id !== 0" flag="ctrl.flag" on-delete="ctrl.onDelete" on-edit="ctrl.onEdit" on-cancel="ctrl.resetFlag"></ionic-flag-edit></div></div><span data-ng-if="ctrl.isLoadingFlags"><ion-spinner class="centerSpinner"></ion-spinner></span>'
        };
    }
})();